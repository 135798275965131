export const banner = {
    color: {
        primary: {
            background: 'bg-primary-50/10',
            radial: 'from-primary-50/30 dark:from-black/10 dark:to-primary-900/30 to-primary-100',
            heading: 'text-primary-500 dark:text-primary-200',
            list: 'text-primary-700 dark:text-primary-400'
        },
        secondary: {
            background: 'bg-secondary-50/10',
            radial: 'from-secondary-50/30 dark:from-black/10 dark:to-secondary-900/30 to-secondary-100',
            heading: 'text-secondary-500 dark:text-secondary-200',
            list: 'text-secondary-700 dark:text-secondary-400'
        },
        tertiary: {
            background: 'bg-tertiary-50/10',
            radial: 'from-tertiary-50/30 dark:from-black/10 dark:to-tertiary-900/30 to-tertiary-100',
            heading: 'text-tertiary-500 dark:text-tertiary-200',
            list: 'text-tertiary-700 dark:text-tertiary-400'
        },
        aux1: {
            background: 'bg-aux1-50/10',
            radial: 'from-aux1-50/30 dark:from-black/10 dark:to-aux1-900/30 to-aux1-100',
            heading: 'text-aux1-500 dark:text-aux1-200',
            list: 'text-aux1-700 dark:text-aux1-400'
        },
        aux2: {
            background: 'bg-aux2-50/10',
            radial: 'from-aux2-50/30 dark:from-black/10 dark:to-aux2-900/30 to-aux2-100',
            heading: 'text-aux2-500 dark:text-aux2-200',
            list: 'text-aux2-700 dark:text-aux2-400'
        },
        aux3: {
            background: 'bg-aux3-50/10',
            radial: 'from-aux3-50/30 dark:from-black/10 dark:to-aux3-900/30 to-aux3-100',
            heading: 'text-aux3-500 dark:text-aux3-200',
            list: 'text-aux3-700 dark:text-aux3-400'
        }
    }
}




export const gradientText = {
    from: {
        default: 'from-secondary-500 dark:from-secondary-200',
        primary: 'from-primary-500 dark:from-primary-200',
        secondary: 'from-secondary-500 dark:from-secondary-200',
        tertiary: 'from-tertiary-500 dark:from-tertiary-200',
        aux1: 'from-aux1-500 dark:from-aux1-200',
        aux2: 'from-aux2-500 dark:from-aux2-200',
        aux3: 'from-aux3-500 dark:from-aux3-200',
    },
    to: {
        default: 'to-primary-500 dark:to-primary-200',
        primary: 'to-primary-500 dark:to-primary-200',
        secondary: 'to-secondary-500 dark:to-secondary-200',
        tertiary: 'to-tertiary-500 dark:to-tertiary-200',
        aux1: 'to-aux1-500 dark:to-aux1-200',
        aux2: 'to-aux2-500 dark:to-aux2-200',
        aux3: 'to-aux3-500 dark:to-aux3-200',
    }
}

export const notice = {
    color: {
        primary: {
            alert: 'ring-primary-200 dark:ring-primary-900 bg-primary-50/50',
            iconBackground: 'bg-primary-100 dark:bg-primary-950',
            iconText: 'text-primary-600 dark:text-primary-500'
        },
        secondary: {
            alert: 'ring-secondary-200 dark:ring-secondary-900 bg-secondary-50/50',
            iconBackground: 'bg-secondary-100 dark:bg-secondary-950',
            iconText: 'text-secondary-600 dark:text-secondary-500'
        },
        tertiary: {
            alert: 'ring-tertiary-200 dark:ring-tertiary-900 bg-tertiary-50/50',
            iconBackground: 'bg-tertiary-100 dark:bg-tertiary-950',
            iconText: 'text-tertiary-600 dark:text-tertiary-500'
        },
        aux1: {
            alert: 'ring-aux1-200 dark:ring-aux1-900 bg-aux1-50/50',
            iconBackground: 'bg-aux1-100 dark:bg-aux1-950',
            iconText: 'text-aux1-600 dark:text-aux1-500'
        },
        aux2: {
            alert: 'ring-aux2-200 dark:ring-aux2-900 bg-aux2-50/50',
            iconBackground: 'bg-aux2-100 dark:bg-aux2-950',
            iconText: 'text-aux2-600 dark:text-aux2-500'
        },
        aux3: {
            alert: 'ring-aux3-200 dark:ring-aux3-900 bg-aux3-50/50',
            iconBackground: 'bg-aux3-100 dark:bg-aux3-950',
            iconText: 'text-aux3-600 dark:text-aux3-500'
        }
    }
}

export const socialList = {
    color: {
        primary: 'text-primary-400',
        secondary: 'text-secondary-400',
        tertiary: 'text-tertiary-400',
        aux1: 'text-aux1-400',
        aux2: 'text-aux2-400',
        aux3: 'text-aux3-400',
    }
}


export const socialShare = {
    color: {
        icon: {
            primary: 'text-primary-500',
            secondary: 'text-secondary-500',
            tertiary: 'text-tertiary-500',
            aux1: 'text-aux1-500',
            aux2: 'text-aux2-500',
            aux3: 'text-aux3-500',
        }
    }
}

export const appBackground = {
    color: {
        primary: 'bg-primary-50 opacity-50 dark:bg-gray-900 dark:opacity-100',
        secondary: 'bg-secondary-50 opacity-50 dark:bg-gray-900 dark:opacity-100',
        tertiary: 'bg-tertiary-50 opacity-50 dark:bg-gray-900 dark:opacity-100',
        aux1: 'bg-aux1-50 opacity-50 dark:bg-gray-900 dark:opacity-100',
        aux2: 'bg-aux2-50 opacity-50 dark:bg-gray-900 dark:opacity-100',
        aux3: 'bg-aux3-50 opacity-50 dark:bg-gray-900 dark:opacity-100',
        white: 'bg-white dark:bg-gray-900 dark:opacity-100',
        gray: 'bg-gray-25 dark:bg-gray-900 dark:opacity-100'
    }
}

export const iconCard = {
    color: {
        primary: {
            ring: 'ring-primary-100 dark:ring-gray-700',
            iconBackground: 'bg-primary-100 dark:bg-primary-200',
            iconText: 'text-primary-500'
        },
        secondary: {
            ring: 'ring-secondary-100 dark:ring-gray-700',
            iconBackground: 'bg-secondary-100 dark:bg-secondary-200',
            iconText: 'text-secondary-500'
        },
        tertiary: {
            ring: 'ring-tertiary-100 dark:ring-gray-700',
            iconBackground: 'bg-tertiary-100 dark:bg-tertiary-200',
            iconText: 'text-tertiary-500'
        },
        aux1: {
            ring: 'ring-aux1-100 dark:ring-gray-700',
            iconBackground: 'bg-aux1-100 dark:bg-aux1-200',
            iconText: 'text-aux1-500'
        },
        aux2: {
            ring: 'ring-aux2-100 dark:ring-gray-700',
            iconBackground: 'bg-aux2-100 dark:bg-aux2-200',
            iconText: 'text-aux2-500'
        },
        aux3: {
            ring: 'ring-aux3-100 dark:ring-gray-700',
            iconBackground: 'bg-aux3-100 dark:bg-aux3-200',
            iconText: 'text-aux3-500'
        },
        gray: {
            ring: 'ring-gray-100 dark:ring-gray-700',
            iconBackground: 'bg-gray-100 dark:bg-gray-800',
            iconText: 'text-gray-600 dark:text-gray-400'
        }
    }
}