<script setup lang="ts">
import type { ErrorSlide } from '../../types';

const props = defineProps<{
  slides: ErrorSlide[],
}>();

const { slides } = toRefs(props);

const carouselRef = ref();
const { autoplay } = useSlider(carouselRef);
autoplay(5000);
</script>

<template>
  <div class="relative rounded-xl overflow-hidden">
    <UCarousel
      ref="carouselRef"
      :items="slides"
      indicators
      :ui="{ indicators: { wrapper: 'bottom-10' }, item: 'basis-full' }"
    >
      <template #default="{ item }">
        <NuxtLink
          :to="item.to || item.href"
          :target="item.href ? '_blank' : '_self'"
          class="flex flex-col justify-end pt-6 px-8 text-center h-full w-full relative square"
        >
          <div class="absolute w-full h-full inset-0 ">
            <nuxt-img
              :src="item.image.public_id"
              width="400"
              height="400"
              fit="fill"
              class="absolute inset-0 object-cover w-full"
            />
            <div
              class="absolute bottom-0 top-1/2 bg-gradient-to-b from-transparent to-black/80 w-full"
            />
          </div>
          <div class="relative bottom-20 text-white">
            <h3
            class="mb-4 text-xl md:text-3xl  tracking-tight"
          >
            {{ item.heading }}
          </h3>
          <p class="text-sm md:text-md md:leading-relaxed">
            {{ item.description }}
          </p>
          </div>
          
        </NuxtLink>
      </template>
    </UCarousel>
  </div>
</template>