import { defaultSlides, mainMenu, footerMenu } from './assets/defaultContent'
import * as components from './assets/components'

export default defineAppConfig({
  settings: {
    meta: {
      image: 'https://res.cloudinary.com/onderwijsin/image/upload/c_scale,w_400,h_400/v1683039896/brand/onderwijsloket-square-OG_xaes77.png',
      socials: [],
      icon: {
        favicon: 'v1683720669/brand/favicon_onderwijsloket_isiqbk',
        appIcon: 'v1683720669/brand/pwa_icon_onderwijsloket_kwjcnx',
        maskableAppIcon: 'v1683720669/brand/pwa_icon_maskable_onderwijsloket_ygeurn',
      }
    },
    ui: {
      useDarkMode: true,
      useDyslexicFont: true,
      useGlobalSiteSearch: false,

      content: {
        errors: {
          300: {
            title: "Omleiding",
            prettyTitle: "Een ogenblik terwijl we je omleiden",
            description: "Je wordt naar een nieuwe bestemming toegestuurd ✨"
          },
          400: {
            title: 'Er ging iets mis',
            prettyTitle: "Er ging iets mis met je verzoek",
            description: "Je kunt het het beste even opnieuw proberen."
          },
          404: {
            title: "Pagina niet gevonden",
            prettyTitle: "Er zijn minstens 404 dingen die we je over het onderwijs kunnen vertellen",
            description: "Maar die vind je hier niet 🤷. Probeer het nog eens via de homepage of neem contact met ons op."
          },
          500: {
            title: "Er ging iets mis",
            prettyTitle: "Er ging iets mis...",
            description: "Er ging iets mis op onze server. Probeer het (later) opnieuw"
          },
        },
        errorSlides: defaultSlides,
      }
    },

    header : {
      fetch: false,
      disableTopMenu: false,
      menu: mainMenu
    },
    footer: {
      fetch: false,
      disableFooterMenu: false,
      menu: footerMenu
    },
    cloudinary: {
      cloudName: 'onderwijsin',
      projectFolder: ''
    },
    savvycal: {
      bookingId: 'f29bd3ff',
      bookingLink: 'onderwijsloket/f29bd3ff',
      bookingUrl: 'https://savvycal.com/onderwijsloket/f29bd3ff',
    },
    privacy: {
      policyUrl: 'https://onderwijs.in/privacy/',
      notice: {
        title: '🍪 Deze site is cookievrij!',
        description: 'Meer weten over hoe we jouw privacy waarborgen?',
        timeout: 12000,
      },
      cookies: [] as CookieList
    }
  },

  components,

  ui: {
    primary: 'main',
    gray: 'grays',

    // Component overrides. Also contains relevant presets from components (otherwise these will be overridden as well)
    // See https://ui.nuxtlabs.com/getting-started for component presets
    alert: {
      title: 'font-bold text-base leading-snug',
      description: 'leading-snug mt-2',
      icon: {
        base: 'relative top-0'
      },
      variant: {
        "subtle": "bg-{color}-50 dark:bg-{color}-300 dark:bg-opacity-10 text-{color}-500 dark:text-{color}-800 ring-1 ring-inset ring-{color}-500 dark:ring-{color}-500 ring-opacity-25 dark:ring-opacity-25"
      }
    },
    avatar: {
      placeholder: 'font-bold',
      text: 'font-bold',
      size: {
        '3xs': 'h-4 w-4 text-[8px]',
        '2xs': 'h-5 w-5 text-[10px]',
        xs: 'h-6 w-6 text-xs',
        sm: 'h-8 w-8 text-sm',
        md: 'h-10 w-10 text-sm',
        lg: 'h-12 w-12 text-sm',
        xl: 'h-14 w-14 text-sm',
        '2xl': 'h-16 w-16 text-base',
        '3xl': 'h-20 w-20 text-base',
        '4xl': 'h-40 w-40 text-xl',
      },
    },
    avatarGroup: {
      wrapper: 'flex flex-row-reverse justify-end'
    },
    breadcrumb: {
      base: 'text-xs'
    },
    button: {
      font: 'font-bold',
      base: "focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 transition-colors click-animation",
      icon: {
        size: {
          "2xs": "h-3 w-3",
          "xs": "h-3 w-3",
          "sm": "h-3 w-3",
          "md": "h-4 w-4",
          "lg": "h-4 w-4",
          "xl": "h-4 w-4"
        }
      },
      color: {
        black: {
          "ghost": "text-black dark:text-white hover:text-gray-900 dark:hover:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-800 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
        },
        white: {
          soft: 'shadow-sm text-gray-900 dark:text-white bg-white hover:bg-gray-50 disabled:bg-white aria-disabled:bg-white dark:bg-gray-800 dark:hover:bg-gray-800/50 dark:disabled:bg-gray-900 dark:aria-disabled:bg-gray-900 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400'
        }
      }
    },
    
    badge: {
      base: "inline-flex items-center select-none",
      "size": {
        "xs": "text-2xs px-1.5 py-0.5 leading-5",
        "2xs": "text-2xs px-[0.25rem] py-[0.1rem] leading-4"
      },
      variant: {
        "solid": "bg-{color}-50 text-{color}-500 ring-1 ring-inset ring-{color}-500",
        "outline": "bg-transparent text-{color}-500 dark:text-{color}-400 ring-1 ring-inset ring-{color}-500 dark:ring-{color}-400"
      },
    },
    card: {
      base: "card overflow-hidden transition-colors flex flex-col",
      background: "bg-white dark:bg-gray-800",
      rounded: "rounded-xl",
      shadow: "shadow-lg shadow-primary-100/50 dark:shadow-black/40",
      ring: 'ring-1 ring-primary-100 dark:ring-gray-600',
      divide: "",
      body: {
        "base": "grow body",
        "background": "",
        "padding": "px-4 py-5"
      },
      header: {
        "base": "font-bold header",
        "background": "",
        "padding": "px-4 pt-6 pb-2"
      },
      footer: {
        "base": "footer",
        "background": "",
        "padding": "px-4 pb-6 pt-2"
      }
    },
    container: {
      "base": "mx-auto w-full",
    },
    checkbox: {
      "wrapper": "checkbox relative flex items-start",
      "base": "h-4 w-4 dark:checked:bg-current dark:checked:border-transparent dark:indeterminate:bg-current dark:indeterminate:border-transparent disabled:opacity-50 disabled:cursor-not-allowed focus:ring-0 focus:ring-transparent focus:ring-offset-transparent relative top-px transitiopn-colors",
      "label": "font-medium text-gray-700 dark:text-gray-200 select-none",
    },
    
    dropdown: {
      item: {
        "padding": "px-4 py-2.5",
      }
    },
    divider: {
      container: {
        base: 'font-bold text-gray-600 dark:text-gray-400'
      },
      border: {
        base: 'dark:border-gray-700'
      }
    },

    formGroup: {
      label: {
        base: 'font-bold text-base'
      }
    },

    kbd: {
      "base": "inline-flex items-center justify-center text-gray-900 dark:text-white transition-colors",
    },

    modal: {
      base: 'modal overflow-hidden h-[100dvh] sm:max-h-[75dvh] min-h-[50dvh]', 
      fullscreen: 'rounded-none h-[100dvh]'
    },

    popover: {
      ring: 'dark:ring-gray-700'
    },
    
    selectMenu: {
      container: 'select-menu'
    },
    skeleton: {
      background: 'dark:bg-gray-700'
    },

    table: {
      "divide": "divide-y divide-gray-300 dark:divide-gray-600",
      "tbody": "divide-y divide-gray-200 dark:divide-gray-700",
      "td": {
        "padding": "px-3 py-2 sm:py-4",
        "size": "text-xs sm:text-sm"
      },
      "th": {
        font: 'font-bold',
      }
    },

    tooltip: {
      base: 'px-3 py-2 h-auto',
      ring: 'dark:ring-gray-700'
    },
  }
})


import type { SocialChannel, ErrorSlide } from './types'
import type { ButtonColor, NotificationAction } from '#ui/types'

type ErrorContent = {
  title: string
  prettyTitle: string
  description: string
}

/* 
NOTE
any props that are declared in the schema type, are for usage in downstream layers, 
and should be set in the downstream app config. If a downstream app config does not 
include any of the declared props, typescript will throw an error.

Any props that are use in the defineAppConfig() of this layer, but are NOT declared 
in the type, are for internal layer usage only
*/
declare module '@nuxt/schema' {
  interface AppConfigInput {
   settings: {
      meta: {
        image: string
        socials?: SocialChannel[] | ((val: SocialChannel[]) => SocialChannel[]),
        icon: {
          favicon: string,
          appIcon: string
          maskableAppIcon: string
        }
      },
      ui: {
        useDarkMode: boolean
        useDyslexicFont: boolean
        useGlobalSiteSearch: boolean

        content: {
          errors: {
            300: ErrorContent,
            400: ErrorContent,
            404: ErrorContent,
            500: ErrorContent,
            [key: number]: ErrorContent; // Optional for any other number
          },
          errorSlides?: ErrorSlide[] | ((val: ErrorSlide[]) => ErrorSlide[])
        }
      },
      header?: {
        fetch?: boolean
        disableTopMenu?: boolean
        menu?: MenuItem[] | ((val: MenuItem[]) => MenuItem[])
      },
      footer?: {
        fetch?: boolean
        disableFooterMenu?: boolean
        menu?: FooterMenuGroup[] | ((val: FooterMenuGroup[]) => FooterMenuGroup[])
      }
      // algolia: {
      //   enable: boolean
      // },
      savvycal?: {
        bookingId: string
        bookingLink: string
        bookingUrl: string
      },
      cloudinary: {
        cloudName?: string
        projectFolder: string
      },
      privacy: {
        policyUrl: string
        notice?: {
          title?: string
          description?: string
          timeout?: number
        },
        cookies?: CookieList | ((val: CookieList) => CookieList) 
      }
   }
  }
}
