<template>
  <UCard
    :ui=" {
      header: {
        padding: 'py-5'
      },
      body: {
        padding: 'pt-2 sm:pt-2'
      },
      ring: useRing ? iconCard.color[color].ring : '',
      ...ui
    }"
  >
    <template #header>
      <div class="flex lg:items-center gap-4">
        <div 
          v-if="icon" 
          class="rounded-md grid place-items-center shrink-0"
          :class="[
            size === 'lg' ? 'w-14 h-14' : 'w-7 h-7 sm:h-8 sm:w-8',
            iconCard.color[color].iconBackground
          ]"
        >
          <UIcon 
            :name="icon" 
            :class="[
              size === 'lg' ? 'w-5 h-5' : 'w-4 h-4',
              iconCard.color[color].iconText
            ]"
          />
        </div>
        <div class="grow">
          <slot name="header" />
        </div>
      </div>
    </template>
    <template v-if="$slots.default" #default>
      <slot name="default" />
    </template>
    <template v-if="$slots.footer" #footer>
      <slot name="footer" />
    </template>
  </UCard>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  ui?: Record<string, any>
  icon?: string
  size?: 'base' | 'lg'
  color: BaseColor | 'gray'
  useRing?: boolean
}>(), {
  size: 'base',
  color: 'primary',
  ui: () => ({})
})

const { iconCard } = useAppConfig().components

</script>

<style>

</style>