<template>
  <UModal 
    :fullscreen="!grid.sm"
    :ui="{
      width: 'sm:max-w-[75vw]',
      overlay: {
        background: 'dark:bg-gray-950/75'
      }
    }"
  >
    <IconCard
      icon="i-mdi-cookie" 
      color="primary"
      :ui="{ 
        base: 'sm:max-h-[90dvh] h-full',
        ring: '', 
        rounded: 'rounded-none sm:rounded-lg',
        divide: 'divide-y divide-gray-100 dark:divide-gray-700',
        shadow: 'shadow-none',
        body: {
          base: 'overflow-scroll',
          padding: 'p-0 sm:p-0'
        }
      }"
    >
      <template #header>
        <div class="flex items-start gap-2 justify-between w-full">
          <h4 class="mb-0 mt-1 sm:mt-1.5 text-gray-600 dark:text-gray-400">Deze cookies gebruiken we</h4>
          <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" size="lg" class="shrink-0" @click="onClose" />
        </div>
        
      </template>

      <UTable 
        :columns="columns"
        :rows="rows"
        :ui="{
          th: {
            base: 'bg-grays-50 dark:bg-gray-700/50 rounded-none select-none',
            padding: 'first:pl-6 last:pr-6 md:px-6 lg:px-8',
            color: 'text-gray-500 dark:text-gray-200',
            size: 'text-xs'
          },
          td: {
            padding: 'first:pl-6 last:pr-6 py-2 sm:py-3 md:py-4 md:px-6 lg:px-8',
            color: 'text-gray-700 dark:text-gray-200',
            base: 'align-top'
          },
          tr: {
            active: 'dark:hover:bg-gray-700/50'
          },
          wrapper: 'overflow-x-auto'
        }"
      >
        <template #name-data="{row}">
          <p><strong>{{row.name}}</strong></p>
        </template>
        <template #type-data="{row}">
          <UBadge color="gray" variant="soft" :label="row.type" size="xs" class="mt-1" />
        </template>
        <template #party-data="{row}">
          <UBadge color="gray" variant="soft" :label="row.party === 'first' ? '1e partij' : '3e partij'" size="xs" class="mt-1" />
        </template>
        <template #maxAge-data="{row}">
          <UBadge color="gray" variant="soft" :label="typeof row.maxAge === 'string' ? row.maxAge : `${daysFromSeconds(row.maxAge)} dagen`" size="xs" class="mt-1" />
        </template>
        <template #secure-data="{row}">
          <UCheckbox v-model="row.secure" disabled :ui="{base: 'disabled:opacity-100 disabled:cursor-default'}" class="mt-1" />
        </template>
        <template #description-data="{row}">
          <p class="w-[24rem] max-w-lg whitespace-normal">{{ row.description }}</p>
        </template>
      </UTable>

      <template #footer>
        <div class="w-full flex flex-row justify-end gap-3">
          <UButton variant="soft" color="gray" @click="onClose">Sluit</UButton>
        </div>
      </template>
    </IconCard>
  </UModal>
</template>

<script lang="ts" setup>
const { grid } = useResponsive()

const emit = defineEmits<{
  (e: 'close'):void
}>()


function onClose() {
  emit('close')
}


const { cookies } = useAppConfig().settings.privacy
const rows = cookies as CookieList
const columns = [
  { key: 'name', label: 'Naam van cookie' },
  { key: 'type', label: 'Type' },
  { key: 'party', label: 'Soort' },
  { key: 'maxAge', label: 'Bewaartermijn' },
  { key: 'secure', label: 'Beveiligd' },
  { key: 'description', label: 'Beschrijving' },
]

const { daysFromSeconds } = useTime()

</script>

<style>

</style>