<script setup>

useHead({
  link: [
    {
      rel: 'icon',
      type: 'image/ico',
      href: '/favicon.ico'
    }
  ]
})

import { _start, _end } from '#tailwind-config/theme/colors'


</script>

<template>
  <div>
    <AppBackground />
    <NuxtRouteAnnouncer />
    <NuxtLoadingIndicator
      :throttle="0"
      :color="`repeating-linear-gradient(to right, ${_start} 0%, ${_end} 100%)`"
    />
    <div class="relative z-20">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>

      <ClientOnly>
          <UContainer>
            <UModals />
          </UContainer>
          <UNotifications
            class="md:w-full md:max-w-md"
          />
        </ClientOnly>
    </div>
  </div>
</template>
