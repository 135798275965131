type Options = {

}

export const useSlider = (carouselRef: Ref<any>, options?: Options) => {
    const defaultDuration = 3000
    const interval = ref()

    const init = (duration?: number) => {
        interval.value = setInterval(() => {
            if (!carouselRef.value) return;
            if (carouselRef.value.page === carouselRef.value.pages) {
                return carouselRef.value.select(0);
            }
            carouselRef.value.next();
        }, duration || defaultDuration);
    }

    const exit = (callback?: Function) => {
        if (interval.value) clearInterval(interval.value);
        if (callback) callback()
    }

    const autoplay = (duration?: number) => {
        onMounted(() => {
            init(duration)
        });
        onBeforeUnmount(() => {
            exit()
        })
    }

    const hideCarouselNavClass = (items: any[]) => {
        if (!items?.length) return ''
        const length = items.length
        let className = ''  
        if (length < 2) className = 'hidden'
        else if (length < 3) className = 'sm:hidden'
        else if (length < 4) className = 'lg:hidden'
        else if (length < 5) className = 'xl:hidden'
        return className
    }
    
    return {
        init,
        exit,
        autoplay,
        hideCarouselNavClass
    }
}