export const useTime = () => {
  // Converts milliseconds to days (rounded to 2 decimal places)
  const daysFromSeconds = (val: number): number => {
    const secondsInADay = 24 * 60 * 60;
    return Math.ceil(val / secondsInADay);
  };
  return {
    daysFromSeconds
  }
}
