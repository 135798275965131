import { computed, defineComponent, h, Suspense } from "vue";
import { NuxtIconCss } from "./css.js";
import { NuxtIconSvg } from "./svg.js";
import { useResolvedName } from "./shared.js";
import { useAppConfig, useNuxtApp } from "#imports";
export default defineComponent({
  name: "NuxtIcon",
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: [Number, String],
      required: false,
      default: null
    },
    customize: {
      type: Function,
      required: false
    },
    suspensible: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, { slots, attrs }) {
    const nuxtApp = useNuxtApp();
    const runtimeOptions = useAppConfig().icon;
    const name = useResolvedName(() => props.name);
    const component = computed(
      () => nuxtApp.vueApp?.component(name.value) || ((props.mode || runtimeOptions.mode) === "svg" ? NuxtIconSvg : NuxtIconCss)
    );
    const style = computed(() => {
      const size = props.size || runtimeOptions.size;
      return size ? { fontSize: Number.isNaN(+size) ? size : size + "px" } : null;
    });
    const customize = props.customize || runtimeOptions.customize;
    return () => h(
      Suspense,
      { suspensible: props.suspensible },
      {
        default: () => h(
          component.value,
          {
            ...runtimeOptions.attrs,
            ...attrs,
            name: name.value,
            class: [
              runtimeOptions.class,
              attrs.class
            ],
            style: [
              style.value,
              attrs.style
            ],
            customize
          },
          slots
        ),
        fallback: () => h("span", { class: "iconify iconify-loading" })
      }
    );
  }
});
