export const useUiState = () => {


    /* Nav menu stuff */
    const menuOpen: Ref<boolean> = useState('menu_open', () => false)
    // This is the Y value the page content will be fixed at
    const savedPos: Ref<number> = useState('saved_pos', () => 0)
    // This is the current scroll position the page will instantly scroll back to once menu is closed
    const scrollBackPos: Ref<number> = useState('scroll_back_pos', () => 0)


    const setMenuState = (payload: { state: boolean, savedPos: number, scrollBack?: number }) => {
        savedPos.value = payload.savedPos
        menuOpen.value = payload.state
        if (typeof payload.scrollBack === 'number') scrollBackPos.value = payload.scrollBack
    }
    const closeMenu = () => {
        menuOpen.value = false
    }


    const isMounted: Ref<boolean> = useState('is_mounted', () => false)

    
    return {
        menuOpen,
        savedPos,
        scrollBackPos,
        setMenuState,
        closeMenu,

        isMounted
    }
}