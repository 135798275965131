import { useScreen, useGrid } from 'vue-screen'

export const useResponsive = () => {

    const screen = useScreen()
    const grid = useGrid({
        'xs': '540px',
        'sm': '640px',
        'md': '768px',
        'lg': '1024px',
        'xl': '1280px',
        '2xl': '1536px',
      })

    return {
        screen,
        grid
    }
}