<template>
  <div 
    class="fixed inset-0 -bottom-20 transition-all" 
    :class="appBackground.color[color]"
  />
</template>

<script lang="ts" setup>

withDefaults(defineProps<{
  color?: BaseColor | 'gray' | 'white'
}>(), {
  color: 'white'
})

const { appBackground } = useAppConfig().components

</script>