const refreshWithMaxRetries = (MAX_RETRIES: number, QUERY_PARAM_NAME: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    const currentRetry = Number(searchParams.get(QUERY_PARAM_NAME)) || 0;
    
    if (currentRetry < MAX_RETRIES) {
        console.log('[nuxt]: Reloading due to chunk error');
        searchParams.set(QUERY_PARAM_NAME, (currentRetry + 1).toString());
        // Add timestamp to force cache bypass
        searchParams.set('t', Date.now().toString());
        
        // Use replace() instead of modifying search directly
        const newUrl = `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
        window.location.replace(newUrl);
    }
}

const MAX_RETRIES = 2;
const QUERY_PARAM_NAME = 'nuxt_reload_attempt'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hook("app:chunkError", (error: any) => {
      console.error("chunkError caught");
      refreshWithMaxRetries(MAX_RETRIES, QUERY_PARAM_NAME)
    });

    const router = useRouter()
    router.onError((error) => {
        if (error.message.includes('Failed to fetch dynamically imported module')) {
            console.error("Failed to fetch dynamically imported module caught");
            refreshWithMaxRetries(MAX_RETRIES, QUERY_PARAM_NAME)
        }
    })

    nuxtApp.hook('app:error', (error) => {
        if (error.message.includes('Loading chunk')) {
            console.error("appError caught");
            refreshWithMaxRetries(MAX_RETRIES, QUERY_PARAM_NAME)
        }
    })
  });