export default defineNuxtPlugin({
    name: 'init-background-processes',
    async setup (nuxtApp) {
      onNuxtReady(() => {
        // Set global isMounted state once!
        const { isMounted } = useUiState()
        isMounted.value = true

        // Init trackers
        const { showPrivacyNotice } = useTracking();
        showPrivacyNotice();

  
      })
    }
})