interface State {
  showPrivacyNotice: boolean
  trackerDate: null | Date
  // algolia: {
  //   anonymousUserToken: null | string
  // }
}
 
export const useTrackingStore = defineStore('tracking', {
  // a function that returns a fresh state
  state: (): State => ({
    showPrivacyNotice: true,
    trackerDate: null,
  }),
  getters: {
    showNotice: (state) => state.showPrivacyNotice,
  },
  actions: {
    noticeShown (payload: { date?: Date | string }) {
      this.showPrivacyNotice = false
      if (payload.date) this.trackerDate = new Date(payload.date)
    },
  },  
  persist: true
})