export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()
    const isProd = config.public.mode.isProd
    const { previewApiKey, apiKey, useDevApi } = config.public.api.onderwijsin
    const url = useDevApi ? 'http://localhost:3000/onderwijsloket/v1' : 'https://api.onderwijs.in/onderwijsloket/v1'
    const api = $fetch.create({
      baseURL: url,
      onRequest({ options }) {
        options.headers.set('Authorization', `Bearer ${apiKey}`)

        if (!isProd && previewApiKey) {
          options.headers.set('preview-api-key', previewApiKey)
        }
      }
    })

    return {
      provide: {
        api
      }
    }
  })