import type { NotificationAction } from "#ui/types"

type TrackingEvent = 'privacy_notice_shown' | 'savvycal'

export interface TrackEventParams {
    event_category: 'engagement' | 'auth' | 'ui' | 'misc' | 'media'
    event_label: string
    event_value?: string
}

import CookieList from "../components/elements/CookieList.vue"


export const useTracking = () => {
    const disable = !!useRuntimeConfig().public.tracking?.disable

    const trackEvent = (event_name: TrackingEvent | string, payload: TrackEventParams) => {
        if (disable || !import.meta.client) return
        useTrackEvent(event_name, { props: { ...payload } })
    }

    

    const showPrivacyNotice = () => {
        if (!import.meta.client) return
        const tracking = useTrackingStore()
        if (tracking.showPrivacyNotice) {
            const toast = useToast()
            const { notice, cookies, policyUrl } = useAppConfig().settings.privacy

            let actions: NotificationAction[] = []

            if (!!cookies?.length) {
                const modal = useModal()
                actions.push({
                    label: 'Bekijk cookies',
                    variant: 'soft',
                    color: 'info',
                    click: () => {
                        modal.open(CookieList, {
                            onClose() {
                                modal.close()
                            }
                        })
                    }
                })
            }

            actions = [...actions, 
                {
                    label: 'Jouw privacy',
                    variant: 'solid',
                    color: 'info',
                    click: () => navigateTo(policyUrl, {
                        external: true,
                        open: {
                            target:'_blank'
                        }
                    })
                }
            ]
            toast.add({
                id: 'cookie_notice',
                ...notice,
                color: 'info',
                actions,
            })
            tracking.noticeShown({ date: new Date()})
            trackEvent('privacy_notice_shown', { event_category: 'misc', event_label: 'cookie_consent'})
        }
    }




    return {
        trackEvent,
        showPrivacyNotice
    }
}