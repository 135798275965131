<script setup>
const props = defineProps({
  error: Object,
});

const app = useAppConfig();
const config = useRuntimeConfig();

// UI / Content stuff
const statusCode = computed(() => parseInt(props.error.statusCode) ?? 500);

const errors = app.settings.ui.content.errors

const content = computed(() => {
  // Check status code, then check if overrides are provided via app.config for default values, else return defaults
  if (statusCode.value >= 500) {
    return errors[500]
  }
  if (statusCode.value === 404) {
    return errors[404]
  }
  if (statusCode.value >= 400) {
    errors[400]
  }
  if (statusCode.value >= 300) {
    return errors[300]
  }

  if (!!errors[statusCode.value]) return errors[statusCode.value]

  return errors[500]
});



const slides = app.settings.ui.content.errorSlides

// Error handling
const route = useRoute();
const router = useRouter();

const lastPath = computed(() => router.options.history.state.back);

const hasFrom = computed(() => {
  if (!!route.redirectedFrom) return false;
  return !!lastPath.value;
});
const handleError = (url) => {
  clearError({ redirect: url ?? lastPath.value });
};

const isClientSide404 = ref(false);
onMounted(() => {
  if (window.location.pathname === "/404" || window.location.pathname === "/404/")
    isClientSide404.value = true;
});


const image = app.settings.meta.image

</script>

<template>
  <Head>
    <Title>{{ content.title }}</Title>
    <Meta name="description" :content="content.desc" />
    <Meta
      name="og:image"
      :content="image"
    />
  </Head>
  <Body>
    <AppBackground color />
    <div class="relative z-20">
      <NuxtLayout name="site">
        <section class="pt-4 sm:pt-8">
          <div
            class="px-6 lg:px-16 py-8 sm:py-16 md:px-12 bg-white dark:bg-gray-800 transition-colors rounded-3xl"
          >
            <div class="grid md:grid-cols-2 gap-10 lg:gap-16">
              <div class="w-full md:flex flex-col justify-center">
                <div class="md:max-w-md">
                  <UBadge
                    class="mb-4"
                    :color="
                      statusCode === 404 || (statusCode >= 300 && statusCode < 400)
                        ? 'warn'
                        : 'error'
                    "
                    size="md"
                    >{{ statusCode }} error</UBadge
                  >
                  <h1
                    class="font-heading sm:mb-4 text-2xl text-primary-400 font-black tracking-tight"
                  >
                    {{ content.prettyTitle }}
                  </h1>
                  <p>{{ content.description }}</p>
                  <div class="flex gap-3 flex-wrap mt-5">
                    <UButton
                      color="primary"
                      size="md"
                      icon="i-heroicons-home-20-solid"
                      @click="handleError('/')"
                    >
                      Naar home
                    </UButton>
                    <ClientOnly>
                      <UButton
                        v-if="hasFrom && !isClientSide404"
                        color="primary"
                        variant="ghost"
                        size="md"
                        icon="i-heroicons-arrow-left-20-solid"
                        @click="handleError()"
                      >
                        Naar vorige pagina
                      </UButton>
                    </ClientOnly>
                  </div>
                </div>
              </div>

              <CardSlider :slides="slides" class="" />
            </div>
          </div>
        </section>
        <UContainer v-if="config.public.mode.isDev" class="py-20 max-w-full">
          <pre class="overflow-x-scroll w-[80vw]">{{ error }}</pre>
        </UContainer>
      </NuxtLayout>
    </div>
  </Body>
</template>
